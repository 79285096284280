import React, { Component } from "react";
import PropTypes from "prop-types";
import Tooltip from "react-tooltip";
import { isMobile } from "common/utils";
import { getDeadline, getDeadlineTooltipContent } from "project/utils";
import DeadlineLabel from "project/components/deadline_label";

export default class HeaderDeadline extends Component {
  render() {
    const { project, user, canClose } = this.props;
    const deadline = getDeadline(project);

    if (!deadline) {
      return null;
    }

    return (
      <span className="project-duration">
        <DeadlineLabel deadline={deadline} />

        <i
          className="fa-solid fa-circle-info"
          data-tip={true}
          data-for="tooltip-deadline"
          data-event={isMobile() ? "click" : undefined}
        />

        <Tooltip
          class="tooltip"
          effect="solid"
          place="left"
          id="tooltip-deadline"
          delayHide={500}
        >
          {getDeadlineTooltipContent({ project, user, canClose })}
        </Tooltip>
      </span>
    );
  }
}

HeaderDeadline.propTypes = {
  project: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  canClose: PropTypes.bool.isRequired,
};
