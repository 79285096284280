import request from "axios";
import { USER_ENTRIES_RESOURCE_NAME } from "admin/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { getValueFromQuery } from "common/sorter/utils";

/* ACTIONS
================================================================================================ */
export const FETCH_ALL = "admin/FETCH_USER_ENTRIES";
export const FETCH_ALL_REQUEST = "admin/FETCH_USER_ENTRIES_REQUEST";
export const FETCH_ALL_SUCCESS = "admin/FETCH_USER_ENTRIES_SUCCESS";

export const CLEAR_ALL = "admin/CLEAR_USER_ENTRIES";

export const SET_VIEW_TYPE = "admin/SET_USER_ENTRIES_VIEW_TYPE";

export const SET_GROUPING = "admin/SET_USER_ENTRIES_GROUPING";

/* HELPERS
================================================================================================ */
export const getEntries = (state) => {
  const { list, grouped } = state.admin.userEntries;
  if (grouped) {
    return list;
  }
  /* if not grouped, return an entry copy for each revision */
  return list.reduce((acc, entry) => {
    entry.revisions.forEach(
      (rev) =>
        (acc = acc.concat({
          ...entry,
          key: `rev_${entry.entry_type}_${rev.id}`,
          revisions: [rev],
        }))
    );
    return acc;
  }, []);
};

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  list: [],
  grouped: false,
  isFetching: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.results,
      };
    case CLEAR_ALL:
      return {
        ...state,
        list: [],
      };
    case SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };
    case SET_GROUPING:
      return {
        ...state,
        grouped: action.payload,
      };
    default:
      return state;
  }
}

export const fetchAll = (userId, query, presetSort) => (dispatch) => {
  /* use a preset sort if provided and not overriden in query */
  const sortValue = getValueFromQuery(USER_ENTRIES_RESOURCE_NAME, query);
  const extraAPIQuery =
    sortValue || !presetSort ? undefined : { sort: presetSort };

  const queryStr = getAPIQueryString(USER_ENTRIES_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(
    `/api/v1/radmin/users/${userId}/list_all_entries/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(USER_ENTRIES_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_ALL,
    promise,
  });
};

export const clearAll = () => {
  return {
    type: CLEAR_ALL,
  };
};

export const setViewType = (type) => {
  return {
    type: SET_VIEW_TYPE,
    payload: type,
  };
};

export const setGrouping = (grouped) => {
  return {
    type: SET_GROUPING,
    payload: grouped,
  };
};
