import request from "axios";
import { RECENT_ENTRIES_RESOURCE_NAME } from "admin/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";

/* ACTIONS
================================================================================================ */
const FETCH_ALL = "admin/recent_entries/FETCH_ALL";
const FETCH_ALL_REQUEST = "admin/recent_entries/FETCH_ALL_REQUEST";
const FETCH_ALL_SUCCESS = "admin/recent_entries/FETCH_ALL_SUCCESS";

const SET_GROUPING = "admin/recent_entries/SET_GROUPING";

/* HELPERS
================================================================================================ */
export const getEntries = (state) => {
  const { list, grouped } = state.admin.recentEntries;
  if (grouped) {
    return list;
  }
  return list.reduce((acc, entry) => {
    entry.revisions.forEach((rev) => {
      acc = acc.concat({
        ...entry,
        key: `rev_${entry.entry_type}_${rev.id}`,
        revisions: [rev],
      });
    });
    return acc;
  }, []);
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  list: [],
  isFetching: false,
  grouped: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        queryStr: action.queryStr,
        isFetching: true,
      };
    case FETCH_ALL_SUCCESS:
      /* avoid wrong results due to parallel requests */
      if (action.queryStr !== state.queryStr) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        list: action.payload.results,
      };
    case SET_GROUPING:
      return {
        ...state,
        grouped: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchAll = (query) => (dispatch) => {
  const queryStr = getAPIQueryString(RECENT_ENTRIES_RESOURCE_NAME, query);

  const promise = request.get(
    `/api/v1/radmin/project_entries/recent/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(RECENT_ENTRIES_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_ALL,
    promise,
    queryStr,
  });
};

export const setGrouping = (grouped) => {
  return {
    type: SET_GROUPING,
    payload: grouped,
  };
};
